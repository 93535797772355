
const site_content_folder = "getsalvor.com"
export default {
    async asyncData({ req, params, route, ssrContext, $content }) {
        const pageSlug = params.pathMatch
        const pages = await $content(`${site_content_folder}/pages`, { deep: true, text: true }).where({ propSlug: pageSlug }).fetch()
        const page = pages[0]
        return { page }
    },
    data() {
        return {
            host: null
        }
    }
}
